// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-estacionamento-rotativo-js": () => import("./../../../src/pages/estacionamento-rotativo.js" /* webpackChunkName: "component---src-pages-estacionamento-rotativo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sections-estacionamento-rotativo-main-js": () => import("./../../../src/pages/sections/estacionamento-rotativo/main.js" /* webpackChunkName: "component---src-pages-sections-estacionamento-rotativo-main-js" */),
  "component---src-pages-sections-home-app-js": () => import("./../../../src/pages/sections/home/app.js" /* webpackChunkName: "component---src-pages-sections-home-app-js" */),
  "component---src-pages-sections-home-best-app-js": () => import("./../../../src/pages/sections/home/best-app.js" /* webpackChunkName: "component---src-pages-sections-home-best-app-js" */),
  "component---src-pages-sections-home-download-js": () => import("./../../../src/pages/sections/home/download.js" /* webpackChunkName: "component---src-pages-sections-home-download-js" */),
  "component---src-pages-sections-home-fleet-js": () => import("./../../../src/pages/sections/home/fleet.js" /* webpackChunkName: "component---src-pages-sections-home-fleet-js" */),
  "component---src-pages-sections-home-main-js": () => import("./../../../src/pages/sections/home/main.js" /* webpackChunkName: "component---src-pages-sections-home-main-js" */),
  "component---src-pages-sections-home-new-cities-js": () => import("./../../../src/pages/sections/home/new-cities.js" /* webpackChunkName: "component---src-pages-sections-home-new-cities-js" */),
  "component---src-pages-sections-home-open-platform-js": () => import("./../../../src/pages/sections/home/open-platform.js" /* webpackChunkName: "component---src-pages-sections-home-open-platform-js" */),
  "component---src-pages-sections-home-screenshot-js": () => import("./../../../src/pages/sections/home/screenshot.js" /* webpackChunkName: "component---src-pages-sections-home-screenshot-js" */),
  "component---src-pages-sections-home-users-js": () => import("./../../../src/pages/sections/home/users.js" /* webpackChunkName: "component---src-pages-sections-home-users-js" */),
  "component---src-pages-sections-zul-mais-asure-js": () => import("./../../../src/pages/sections/zul-mais/asure.js" /* webpackChunkName: "component---src-pages-sections-zul-mais-asure-js" */),
  "component---src-pages-sections-zul-mais-dealers-js": () => import("./../../../src/pages/sections/zul-mais/dealers.js" /* webpackChunkName: "component---src-pages-sections-zul-mais-dealers-js" */),
  "component---src-pages-sections-zul-mais-fines-js": () => import("./../../../src/pages/sections/zul-mais/fines.js" /* webpackChunkName: "component---src-pages-sections-zul-mais-fines-js" */),
  "component---src-pages-sections-zul-mais-fuel-js": () => import("./../../../src/pages/sections/zul-mais/fuel.js" /* webpackChunkName: "component---src-pages-sections-zul-mais-fuel-js" */),
  "component---src-pages-sections-zul-mais-install-js": () => import("./../../../src/pages/sections/zul-mais/install.js" /* webpackChunkName: "component---src-pages-sections-zul-mais-install-js" */),
  "component---src-pages-sections-zul-mais-main-js": () => import("./../../../src/pages/sections/zul-mais/main.js" /* webpackChunkName: "component---src-pages-sections-zul-mais-main-js" */),
  "component---src-pages-sections-zul-mais-mall-parking-js": () => import("./../../../src/pages/sections/zul-mais/mall-parking.js" /* webpackChunkName: "component---src-pages-sections-zul-mais-mall-parking-js" */),
  "component---src-pages-sections-zul-mais-parking-js": () => import("./../../../src/pages/sections/zul-mais/parking.js" /* webpackChunkName: "component---src-pages-sections-zul-mais-parking-js" */),
  "component---src-pages-sections-zul-mais-payments-js": () => import("./../../../src/pages/sections/zul-mais/payments.js" /* webpackChunkName: "component---src-pages-sections-zul-mais-payments-js" */),
  "component---src-pages-sections-zul-mais-tag-js": () => import("./../../../src/pages/sections/zul-mais/tag.js" /* webpackChunkName: "component---src-pages-sections-zul-mais-tag-js" */),
  "component---src-pages-sections-zul-mais-traffic-restriction-js": () => import("./../../../src/pages/sections/zul-mais/traffic-restriction.js" /* webpackChunkName: "component---src-pages-sections-zul-mais-traffic-restriction-js" */),
  "component---src-pages-sections-zul-mais-value-js": () => import("./../../../src/pages/sections/zul-mais/value.js" /* webpackChunkName: "component---src-pages-sections-zul-mais-value-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-zul-mais-js": () => import("./../../../src/pages/zul-mais.js" /* webpackChunkName: "component---src-pages-zul-mais-js" */)
}

